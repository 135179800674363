import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
import cities from 'modules/project/config/cities.json'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.project', route: { name: 'project' } },
      ],
      listRoute: () => ({ name: 'project' }),
      afterCreateRoute: result => ({
        name: 'project-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.project',
      defaultData: this._defaultData,
      blocks: [
        {
          data: {
            banner_id: {
              label: 'data.banner',
              type: 'banner',
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },
        {
          title: 'form_block.base',
          data: {
            name: {
              required: true,
              label: 'data.name',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            type: {
              label: 'project.data.type',
              type: 'selection',
              options: (formInfo: FormInfoInterface) => {
                if(!this.vm) return []
                return this.vm?.$projectTypes.map(type => ({
                  text: this.vm?.$t(`project.data.type.${type}`),
                  value: type,
                }))
              },
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return !window.eagleLodash.isEqual(originalData, data)
              },
            },
            status: {
              grid: { xl: 12, lg: 12, md: 12 },
              label: 'data.status',
              type: 'switch',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
            city: {
              label: 'data.city',
              type: 'selection',
              optionTextKey: 'city',
              optionValueKey: 'city',
              options: (formInfo: FormInfoInterface) => cities,
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return !window.eagleLodash.isEqual(originalData, data)
              },
            },
            district: {
              label: 'data.district',
              type: 'selection',
              optionTextKey: 'name',
              optionValueKey: 'name',
              options: (formInfo: FormInfoInterface) => {
                if(!formInfo.formData) return []
                const city = formInfo.formData.city
                const cityObject = cities.find(item => item.city === city)
                if(!cityObject) return []
                if(!Array.isArray(cityObject.districts)) return []
                return cityObject.districts
              },
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return !window.eagleLodash.isEqual(originalData, data)
              },
            },
            capacity: {
              label: 'project.data.capacity',
              help: 'project.data.capacity.help',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            period: {
              label: 'project.data.period',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            outsourcing_by: {
              label: 'project.data.outsourcing_by',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            brief: {
              label: 'data.brief',
              type: 'textarea',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            content: {
              label: 'data.content',
              grid: { xl: 12, lg: 12, md: 12 },
              type: 'html',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            photos: {
              label: 'data.photo',
              type: 'photo',
              photoType: 'project',
              photoManager: true,
              multiple: true,
              total: 30,
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, photoListEqual}: FormDataChangeHelperInterface) => {
                return photoListEqual(originalData, data) === false
              },
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
    }
  }
}

export default new formConfig()
